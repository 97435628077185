/* SettingsPage.css */
.settings-container {
  
}

.settings-form {

}

.settings-form h1 {
  margin-bottom: 1rem;
  color: #333;
  text-align: center;
}

.settings-form .form-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.settings-form .form-group label {
  width: 50%;
  color: #555;
}

.settings-form .form-group input[type="checkbox"],
.settings-form .form-group .slider-container {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.settings-form .form-group input[type="range"] {
  width: calc(100% - 40px);
}

.settings-form .slider-value {
  width: 40px;
  text-align: right;
  color: #555;
}

.settings-form .button-container {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.settings-form .button-container button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
}

.settings-form .button-container button:hover {
  background-color: #0056b3;
}