/* VocabularyPanel.css */
.default-background {
    background-color: #f0f2f5; /* Add your desired background color here */
    min-height: 100vh; /* Ensures minimum height of full window */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.button-container {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
}
  
.button-container button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 1.5rem 3rem; /* Double the size */
    border-radius: 4px;
    cursor: pointer;
    font-size: 1.2rem; /* Increase font size */
}
  
.button-container button:hover {
    background-color: #0056b3;
}