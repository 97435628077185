/* VocabularyPanel.css */
.vocabulary-center {
    width: 90%;              /* Sets the width of the component to 30% of its parent container */
    margin: auto;            /* Centers the component horizontally */
    background-color: #bdbdbd; /* Light gray background */
    border-radius: 15px;     /* Rounded corners */
    padding: 20px;           /* Padding inside the container */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: adds shadow for better visibility */
    text-align: center;      /* Centers the text inside the div */
    color: black;
}