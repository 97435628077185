.CardPanel-panel {
    width: 90%;
    margin: auto;
    background-color: #ededed;
    border-radius: 15px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    color: black;
    min-height: 60vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: grab;
}

.CardPanel-container {
    display: flex;
    flex-direction: column;
    height: 100%; /* Ensure the parent container takes up the full height */
}

.CardPanel-text {
    flex: 1; /* Each child will take up an equal portion of the parent's height */
    display: flex;
    justify-content: center; /* Center content horizontally */
    align-items: center; /* Center content vertically */
    font-size: 3rem; 
}
