/* NavBar.css */

.top-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: #f8f9fa;
  padding: 10px 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: fixed; /* Ensures the navbar stays at the top */
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000; /* Ensures it stays above other content */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
}

.left-side {
  display: flex;
  align-items: center;
}

.left-side .icon-class {
  margin-right: 10px;
  font-size: 24px;
  /* Add any other styling for your icon */
}

.middle {
  display: flex;
  gap: 20px;
}

.middle a {
  text-decoration: none;
  color: #333;
  font-weight: bold;
}

.right-side {
  display: flex;
  align-items: center;
  gap: 10px; /* Ensures proper spacing between items */
  white-space: nowrap; /* Prevents text from wrapping */
}

.profile-image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.logout-button {
  background-color: #ff4b5c;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.logout-button:hover {
  background-color: #ff3b4c;
}
