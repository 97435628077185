/* LoginPage.css */
.login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f0f2f5;
  }
  
  .login-form {
    background-color: white;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    width: 100%;
  }
  
  .login-form h1 {
    margin-bottom: 1rem;
    color: #333;
  }
  
  .login-form label {
    display: block;
    margin-bottom: 0.5rem;
    color: #555;
  }
  
  .login-form input {
    width: 100%;
    padding: 0.5rem;
    margin-bottom: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .login-form button {
    width: 100%;
    padding: 0.75rem;
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    color: white;
    font-size: 1rem;
    cursor: pointer;
    margin-top: 1rem;
  }
  
  .login-form button:hover {
    background-color: #0056b3;
  }
  
  .login-form .error {
    color: red;
    margin-top: 1rem;
  }
  
  .login-form .link-button {
    margin-top: 1rem;
    display: block;
    text-align: center;
    color: #007bff;
    text-decoration: none;
  }
  
  .login-form .link-button:hover {
    text-decoration: underline;
  }